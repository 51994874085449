import plane from 'assets/img/plane.gif';
import { Typography } from 'components';
import { useEffect, useState } from 'react';
import { FadeInContainer, ImageWrapper, InteractionContainer } from './styles';

const facts: string[] = [
  'Shortest flight: 53 seconds, Westray to Papa Westray in Scotland.',
  'First in-flight meal served in 1920 on a London to Paris flight.',
  "Airbus A380's wingspan is longer than the Wright brothers' first flight.",
  'Fear of flying is called aviophobia, affecting many worldwide.',
  'First-class cabins have fire-resistant money bags for wealthy passengers.',
  'Boeing produced a version of the 727 with backup rocket engines to assist takeoff.',
  'In 1987, American Airlines saved $40,000 by removing one olive from each first-class salad.',
  "Boeing 747's hump was initially for cargo, later becoming iconic.",
  'Cabin air is filtered and recirculated every 2–3 minutes during a flight.',
  "In 2007, a British man changed his name to 'Yorkshire Airlines' to avoid a name-change fee on his ticket.",
];

const CHANGE_FACT_IN_SECONDS = 4000;

export const InteractionLoader = () => {
  const [randomFact, setRandomFact] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * facts.length);
    setRandomFact(facts[randomIndex]);
    setIsVisible(true);

    const intervalId = setInterval(() => {
      const newIndex = Math.floor(Math.random() * facts.length);
      setRandomFact(facts[newIndex]);
      setIsVisible(true);
    }, CHANGE_FACT_IN_SECONDS);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <InteractionContainer>
      <FadeInContainer className={isVisible ? 'visible' : 'hidden'}>
        <Typography variant="title">{randomFact}</Typography>
      </FadeInContainer>
      <ImageWrapper>
        <img src={plane} alt="plane" />
      </ImageWrapper>
    </InteractionContainer>
  );
};
